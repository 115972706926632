var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"summary-element d-flex px-1",class:[
    {
      'summary-element--read-only': _vm.readOnly,
      'summary-element--highlighted': _vm.isHovered && !_vm.isEditing,
      'summary-element--editing': _vm.isEditing,
      'summary-element--dense': _vm.dense,
      'pr-0 pl-3': _vm.dense,
    },
    `align-${_vm.align}`,
  ],on:{"click":_vm.onClick}},[_c('div',{staticClass:"summary-element__label caption font-weight-regular",class:{ 'mr-1': !_vm.dense },attrs:{"title":_vm.label || _vm.input.label}},[_vm._v("\n    "+_vm._s(_vm.label || _vm.input.label)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"summary-element__content w-100"},[(_vm.readOnly)?_vm._t("default",function(){return [_vm._v("\n      "+_vm._s(_vm.valueText)+"\n    ")]}):_vm._e(),_vm._v(" "),(_vm.readOnly)?_vm._t("append"):[(!_vm.isEditing)?_c('div',{on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"d-flex justify-start align-center max-w-100"},[_c('div',{staticClass:"d-flex align-center justify-start w-100"},[_vm._t("value-text",function(){return [_c('span',{staticClass:"caption font-weight-regular text-truncate max-w-100"},[_vm._v("\n                "+_vm._s(_vm.inputTypeText ? _vm.valueText : "********")+"\n              ")])]}),_vm._v(" "),_vm._t("append")],2),_vm._v(" "),(!_vm.dense)?_c('div',{staticClass:"summary-element__action-btn"},[(_vm.isHovered)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.isEditing = true}}},[_c('v-icon',[_vm._v("fas fa-pen")])],1):_vm._e()],1):_vm._e()])]):_c('div',{staticClass:"summary-element__input-wrapper"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('ValidationProvider',{attrs:{"name":_vm.input.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(_vm.inputTypes[_vm.input.type],{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
                include: _vm.getClickOutsideTarget,
              }),expression:"{\n                include: getClickOutsideTarget,\n              }"}],ref:"field",tag:"component",attrs:{"invalid":invalid,"dataset":_vm.input.dataset,"error-messages":errors,"item-text":"name","item-value":"value"},on:{"change":_vm.onChange,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)}},model:{value:(_vm.input.value),callback:function ($$v) {_vm.$set(_vm.input, "value", $$v)},expression:"input.value"}})]}}],null,true)})]}}])})],1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }