<template>
  <ERow no-gutters class="summary-map mt-n1">
    <ECol :cols="12">
      <MapFov :cameraId="cameraDialogStore.camera.exid" class="w-100" />
    </ECol>
    <ECol :cols="12">
      <EZoomableImg
        disabled
        :hide-content-on-blur="false"
        :slider="false"
        :src="testSnapshotUrl || cameraDialogStore.snapshot"
        :height="300"
        :img-props="{
          crossorigin: 'anonymous',
        }"
        :is-widget="accountStore.isWidget"
        @error="onSnapshotLoadError"
      >
        <div class="h-100 w-100 d-flex">
          <v-fade-transition>
            <div
              v-if="cameraDialogStore.isFetchingSnapshot"
              class="position-absolute z-index-1 h-100 w-100 d-flex justify-center align-center black opacity-40"
            >
              <EvercamLoadingAnimation :verify-theme-mode="false" />
            </div>
          </v-fade-transition>
          <div class="d-inline mt-auto ml-auto pa-3">
            <v-btn
              small
              :disabled="cameraDialogStore.isFetchingSnapshot"
              @click="loadTestSnapshot"
            >
              Test snapshot <v-icon class="ml-2">fa-sync-alt</v-icon>
            </v-btn>
          </div>
        </div>
      </EZoomableImg>
    </ECol>
    <ECol v-if="ifHikvision" :cols="12" class="mt-3 text-center">
      <!-- Camera NVR Common Commands Buttons -->
      <v-btn
        v-if="cameraDialogStore.camera.cameraHttpPort"
        color="primary"
        class="ml-0 mt-1"
        small
        :loading="cameraRebootloading"
        @click="rebootCameraNVR('Camera')"
      >
        Reboot Camera
      </v-btn>
      <v-btn
        v-if="cameraDialogStore.camera.nvrHttpPort"
        color="primary"
        class="ml-0 mt-1"
        :loading="nvrRebootloading"
        small
        @click="rebootCameraNVR('NVR')"
      >
        Reboot NVR
      </v-btn>
      <v-btn
        color="primary"
        class="ml-0 mt-1"
        :loading="focusButtonLoading"
        small
        @click="focusCamera"
      >
        Focus Camera
      </v-btn>
    </ECol>
  </ERow>
</template>

<script lang="ts">
import MapFov from "@/components/cameraTabs/details/MapFov"
import { CameraStatus } from "@evercam/shared/types/camera"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useAccountStore } from "@evercam/dashboard/stores/account"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default Vue.extend({
  name: "CameraSummaryMap",
  components: {
    MapFov,
    EvercamLoadingAnimation,
  },
  data() {
    return {
      testSnapshotUrl: "",
      cameraRebootloading: false,
      nvrRebootloading: false,
      focusButtonLoading: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useAccountStore),
    ifHikvision() {
      return this.cameraDialogStore.camera?.cameraModel?.includes("hikvision")
    },
  },
  watch: {
    "cameraDialogStore.snapshot"(value) {
      if (value) {
        this.testSnapshotUrl = ""
      }
    },
  },
  methods: {
    rebootCameraNVR(device) {
      if (device === "Camera") {
        this.cameraRebootloading = true
        AdminApi.cameras
          .rebootCamera(this.cameraDialogStore.camera.exid)
          .then(() => {
            this.$notifications.success(`${device} rebooted successfully.`)
            this.cameraRebootloading = false
          })
          .catch((error) => {
            this.$notifications.error({
              text: `Could not reboot the ${device}`,
              error,
            })
            this.cameraRebootloading = false
          })
      } else if (device === "NVR") {
        this.nvrRebootloading = true
        AdminApi.nvrs
          .rebootNVR(this.cameraDialogStore.camera.exid)
          .then(() => {
            this.$notifications.success(`${device} rebooted successfully.`)
            this.nvrRebootloading = false
          })
          .catch((error) => {
            this.$notifications.error({
              text: `Could not reboot the ${device}`,
              error,
            })
            this.nvrRebootloading = false
          })
      }
    },
    focusCamera() {
      this.focusButtonLoading = true
      AdminApi.cameras
        .focusCamera(this.cameraDialogStore.camera.exid, "camera")
        .then(() => {
          this.$notifications.success("Camera Focused successfully.")
          this.focusButtonLoading = false
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Could not focus the camera!",
            error,
          })
          this.focusButtonLoading = false
        })
    },
    async loadTestSnapshot() {
      this.cameraDialogStore.isFetchingSnapshot = true
      let port = this.cameraDialogStore.camera.cameraHttpPort
      let model = this.cameraDialogStore.camera.cameraModel
      let scheme =
        this.cameraDialogStore.camera.nvrModel === "ex_nvr" ? "https" : "http"
      if (this.cameraDialogStore.camera.recordingFromNvr) {
        port = this.cameraDialogStore.camera.nvrHttpPort
        model = this.cameraDialogStore.camera.nvrModel
      }

      try {
        const { data } = await AdminApi.cameras.testSnapshot({
          externalUrl: `${scheme}://${this.cameraDialogStore.camera.cameraHost}:${port}`,
          jpgUrl: this.cameraDialogStore.camera.recordingFromNvr
            ? this.cameraDialogStore.camera.nvrPictureEndpoint
            : this.cameraDialogStore.camera.cameraPictureEndpoint,
          camUsername: this.cameraDialogStore.camera.cameraUsername,
          camPassword: this.cameraDialogStore.camera.cameraPassword,
          model: model,
          cameraExid: this.cameraDialogStore.camera.exid,
        })
        this.testSnapshotUrl = data
        if (this.cameraDialogStore.camera.status !== CameraStatus.Online) {
          this.cameraDialogStore.updateCamera({ status: CameraStatus.Online })
          this.$root.$emit("camera-updated", {})
        }
      } catch (error) {
        this.$notifications.error({
          text: "Failed to load test Snaposhot",
          error,
        })
      } finally {
        this.cameraDialogStore.isFetchingSnapshot = false
      }
    },
    onSnapshotLoadError() {
      this.testSnapshotUrl = require("~/assets/img/waiting_for_activation.jpg")
    },
  },
})
</script>

<style lang="scss">
.summary-map {
  min-height: 310px;
  .zoomable-img__slider-container {
    bottom: 0;
    right: 16px;
    left: auto;
  }
}
</style>
