<template>
  <div>
    <SummaryElement
      v-model="status"
      :read-only="disabled"
      @change="changeSchedule()"
    />
    <ERow v-if="status.value === 'on-scheduled'" class="mb-2">
      <ECol cols="4" class="text-right black--text">Schedule</ECol>
      <ECol cols="8">
        <v-chip
          v-for="(item, key) in scheduleDisplay"
          :key="key"
          label
          small
          class="mr-1 mb-2"
        >
          <b>{{ key.toUpperCase()[0] }}</b
          >&nbsp;
          <span>{{ item[0] || "N/A" }}</span>
        </v-chip>
        <v-dialog v-model="scheduleDialog" width="500" persistent>
          <template #activator="{ on, attrs }">
            <v-chip color="primary" label small v-bind="attrs" v-on="on">
              Edit schedule
              <v-icon right>fas fa-pen</v-icon>
            </v-chip>
          </template>
          <v-card>
            <v-card-title>Cloud recording schedule</v-card-title>
            <v-divider />
            <v-card-text class="pt-4">
              <Schedule
                v-model="schedule"
                :preload-schedule="schedule"
                :schedule-type="scheduleType"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="resetSchedule"> Cancel </v-btn>
              <v-btn color="primary" text @click="updateCloudRecordingSchedule"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ECol>
    </ERow>
    <SummaryElement v-model="duration" :read-only="disabled" />
    <SummaryElement v-model="frequency" :read-only="disabled" />
    <SummaryElement v-model="storage" read-only align="center">
      <div v-for="(storageServer, i) in filteredStorageServers" :key="i">
        {{ getStorageServerLabel(storageServer) }}
        <OpenInNewWindowButton
          :target-link="`${storageServer.url}/${cameraDialogStore.camera.exid}`"
          hint="Open Storage Server In New Window"
          :class="{ 'pl-9': i === 0 && filteredStorageServers.length > 1 }"
        />
      </div>
    </SummaryElement>
  </div>
</template>

<script>
import SummaryElement from "@/components/cameraTabs/summary/SummaryElement.vue"
import Schedule from "@/components/Schedule.vue"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import schedules from "@evercam/shared/constants/schedules"
import { ScheduleType } from "@evercam/shared/types"
import { sortScheduleByDay } from "@evercam/shared/utils"
import {
  CloudRecordingsFrequencies,
  CloudRecordingsStorageDuration,
  CloudRecordingsStatuses,
} from "@/components/constants.js"
import { mapStores } from "pinia"
import { useStorageStore } from "@/stores/storage"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: { SummaryElement, Schedule, OpenInNewWindowButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: {
        key: "status",
        label: "CR Status",
        value: "",
        type: "SINGLE_SELECT",
        dataset: CloudRecordingsStatuses,
        cloudRecordingId: null,
        isCloudRecording: true,
      },
      duration: {
        key: "storageDuration",
        label: "CR Duration",
        value: "",
        type: "SINGLE_SELECT",
        dataset: CloudRecordingsStorageDuration,
        cloudRecordingId: null,
        isCloudRecording: true,
      },
      frequency: {
        key: "frequency",
        label: "CR Frequency",
        value: "",
        type: "SINGLE_SELECT",
        dataset: CloudRecordingsFrequencies,
        cloudRecordingId: null,
        isCloudRecording: true,
      },
      storage: {
        key: "storageServers",
        label: "Storage servers",
        value: "",
      },
      scheduleDialog: false,
      schedule: null,
      scheduleType: ScheduleType.OnSchedule,
    }
  },
  computed: {
    ...mapStores(useStorageStore, useCameraDialogStore),
    filteredStorageServers() {
      let oldestSnapshotDate
      if (this.cameraDialogStore.camera.cloudRecordingStorageDuration > -1) {
        oldestSnapshotDate = this.$moment(new Date()).subtract({
          days: this.cameraDialogStore.camera.cloudRecordingStorageDuration,
        })
      } else {
        oldestSnapshotDate = this.cameraDialogStore.camera.createdAt
      }

      return this.storageStore.storageServers.filter(({ toDate }) => {
        return this.$moment(toDate).isAfter(this.$moment(oldestSnapshotDate))
      })
    },
    scheduleDisplay() {
      const schedule =
        typeof this.schedule === "string"
          ? JSON.parse(this.schedule)
          : { ...this.schedule }

      return sortScheduleByDay(schedule)
    },
  },
  watch: {
    "cameraDialogStore.camera": {
      immediate: true,
      deep: true,
      handler() {
        this.setCloudRecordingsData()
      },
    },
  },
  mounted() {
    this.storageStore.fetchStorageServers()
    this.setCloudRecordingsData()
  },
  methods: {
    resetSchedule() {
      this.scheduleDialog = false
      this.schedule = this.cameraDialogStore.camera.cloudRecordingSchedule
    },
    changeSchedule() {
      if (this.schedule) {
        this.schedule = JSON.stringify(schedules.FULL)
      }
    },
    async updateCloudRecordingSchedule() {
      if (typeof this.schedule !== "object") {
        this.schedule = JSON.parse(this.schedule)
      }
      if (this.status?.value !== "on-scheduled") {
        this.schedule = schedules.FULL
      }

      let params = {
        cloudRecordings: {
          id: this.cameraDialogStore.camera.cloudRecordingId,
          schedule: this.schedule,
        },
      }
      await this.cameraDialogStore.updateCamera(params)
      this.scheduleDialog = false
    },
    getStorageServerLabel({ fromDate, toDate }) {
      const cameraCreationDate = this.$moment(
        this.cameraDialogStore.camera.createdAt
      )
      const startDate = this.$moment(fromDate)
      const endDate = this.$moment(toDate)
      const endDateFormatted = endDate.isBefore(new Date())
        ? this.$moment(toDate).format("DD/MM/YYYY")
        : "present"
      const startDateFormatted = startDate.isBefore(cameraCreationDate)
        ? cameraCreationDate.format("DD/MM/YYYY")
        : startDate.format("DD/MM/YYYY")

      return `${startDateFormatted} to ${endDateFormatted}`
    },
    setCloudRecordingsData() {
      this.schedule = this.cameraDialogStore.camera?.cloudRecordingSchedule

      this.duration.value = this.duration.dataset.find(
        (el) =>
          el.value ===
          this.cameraDialogStore.camera.cloudRecordingStorageDuration
      )?.value
      this.duration.cloudRecordingId =
        this.cameraDialogStore.camera.cloudRecordingId

      this.frequency.value = this.frequency.dataset.find(
        (el) =>
          el.value === this.cameraDialogStore.camera.cloudRecordingFrequency
      )?.value
      this.frequency.cloudRecordingId =
        this.cameraDialogStore.camera.cloudRecordingId

      this.status.value = this.status.dataset.find(
        (el) => el.value === this.cameraDialogStore.camera.cloudRecordingStatus
      )?.value
      this.status.cloudRecordingId =
        this.cameraDialogStore.camera.cloudRecordingId
    },
  },
}
</script>
