<template>
  <div
    class="summary-element d-flex px-1"
    :class="[
      {
        'summary-element--read-only': readOnly,
        'summary-element--highlighted': isHovered && !isEditing,
        'summary-element--editing': isEditing,
        'summary-element--dense': dense,
        'pr-0 pl-3': dense,
      },
      `align-${align}`,
    ]"
    @click="onClick"
  >
    <div
      class="summary-element__label caption font-weight-regular"
      :class="{ 'mr-1': !dense }"
      :title="label || input.label"
    >
      {{ label || input.label }}
    </div>
    <div class="summary-element__content w-100">
      <!-- Read only default @slot -->
      <slot v-if="readOnly">
        {{ valueText }}
      </slot>

      <slot v-if="readOnly" class="summary-element__slot" name="append"></slot>

      <!-- Edit button -->
      <template v-else>
        <div
          v-if="!isEditing"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <div class="d-flex justify-start align-center max-w-100">
            <div class="d-flex align-center justify-start w-100">
              <slot name="value-text">
                <span
                  class="caption font-weight-regular text-truncate max-w-100"
                >
                  {{ inputTypeText ? valueText : "********" }}
                </span>
              </slot>
              <slot class="summary-element__slot" name="append"></slot>
            </div>
            <div v-if="!dense" class="summary-element__action-btn">
              <v-btn v-if="isHovered" small icon @click="isEditing = true">
                <v-icon>fas fa-pen</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Editing field -->
        <div v-else class="summary-element__input-wrapper">
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <ValidationProvider
              v-slot="{ errors }"
              :name="input.label"
              :rules="rules"
            >
              <component
                :is="inputTypes[input.type]"
                ref="field"
                v-model="input.value"
                v-click-outside="{
                  include: getClickOutsideTarget,
                }"
                :invalid="invalid"
                :dataset="input.dataset"
                :error-messages="errors"
                item-text="name"
                item-value="value"
                @change="onChange"
                @keyup.enter="save"
              />
            </ValidationProvider>
          </ValidationObserver>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SelectInput from "@/components/cameraTabs/summary/inputs/SelectInput.vue"
import TextFieldInput from "@/components/cameraTabs/summary/inputs/TextFieldInput.vue"
import { CameraStatus } from "@evercam/shared/types/camera"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: String,
      default: null,
    },
    inputTypeText: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    hasDefaultSaveCallback: {
      type: Boolean,
      default: true,
    },
    customReadOnlyDisplay: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      inputTypes: {
        SINGLE_SELECT: SelectInput,
        TEXT_FIELD: TextFieldInput,
      },
      input: {},
      isHovered: false,
      isEditing: false,
      hasChanged: false,
      initialInput: {},
      actionConfirmation: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    valueText() {
      let result = null
      if (this.initialInput?.type === "TEXT_FIELD") {
        if (![null, ""].includes(this.initialInput.value)) {
          result = this.initialInput.value
        }
      }

      if (
        this.initialInput?.type === "SINGLE_SELECT" &&
        this.initialInput?.dataset?.length > 0
      ) {
        result = this.initialInput.dataset.find(
          (el) => el.value === this.initialInput.value
        )?.name
      }

      if (!result) {
        result = "-"
      }

      return result
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.hasChanged) {
          return
        }
        this.input = Object.assign({}, this.value)
        this.initialInput = Object.assign({}, this.value)
      },
    },
    isEditing(val) {
      if (!val) {
        return
      }
      this.$setTimeout(() => {
        this.focusField()
        this.openSelectFieldMenu()
      })
    },
  },
  methods: {
    close() {
      this.input = Object.assign({}, this.value)
      this.isEditing = false
      this.isHovered = false
    },
    async save() {
      const isValid = await this.$refs.obs?.validate()
      if (!isValid) {
        return
      } else if (!this.hasChanged) {
        this.close()

        return
      }

      // CAMERA DECOMMISSIONING HANDLE *START*
      // TODO: if more special cases emerge, move all special logic to a different file
      if (!this.actionConfirmation) {
        this.hasChanged = false
        this.close()

        return
      }
      // CAMERA DECOMMISSIONING HANDLE *END*

      if (
        this.hasDefaultSaveCallback &&
        !this.input.isModel &&
        this.input.value !== "other"
      ) {
        const params = this.getParams()
        await this.cameraDialogStore.updateCamera(params)
      } else {
        this.$emit("save")
      }
      this.initialInput = Object.assign({}, this.input)
      this.hasChanged = false
      this.close()
    },
    getParams() {
      let params = { [this.input.key]: this.input.value }

      if (this.input.isCameraConfig) {
        params = { cameraConfig: params }
      } else if (this.input.isNvrConfig) {
        params = { nvr: params }
      } else if (this.input.isCloudRecording) {
        params = {
          cloudRecordings: { id: this.input.cloudRecordingId, ...params },
        }
      } else if (this.input.isCameraLocation) {
        const [lat, lng] = this.input.value.split(",")

        params = {
          coordinates: [parseFloat(lng), parseFloat(lat)],
        }
      }

      return params
    },
    onClick() {
      if (!this.readOnly && !this.customReadOnlyDisplay) {
        this.isEditing = true
      }
    },
    onMouseEnter() {
      if (!this.readOnly && !this.customReadOnlyDisplay) {
        this.isHovered = true
      }
    },
    onMouseLeave() {
      if (!this.readOnly && !this.customReadOnlyDisplay) {
        this.isHovered = false
      }
    },
    focusField() {
      const field = this.$refs.field || {}
      if (this.value?.type !== "TEXT_FIELD") {
        return
      }
      field.$refs?.input?.focus()
    },
    openSelectFieldMenu() {
      if (this.value?.type !== "SINGLE_SELECT") {
        return
      }
      const field = this.$refs.field || {}
      const menu = field.$children[0]

      // Check if the menu exists and has an activateMenu() method
      if (menu && menu.activateMenu) {
        menu.activateMenu() // Call the activateMenu() method to open the menu
      }
    },
    async onChange() {
      // CAMERA DECOMMISSIONING HANDLE *START*
      // TODO: if more special cases emerge, move all special logic to a different file
      if (
        this.input.key == "status" &&
        this.input.value == CameraStatus.Decommissioned
      ) {
        if (
          !(await this.$confirmDialog.open({
            title: "Confirm new status ?",
            message: "This will permanently decommission the camera!",
          }))
        ) {
          this.actionConfirmation = false
          this.hasChanged = false
          this.input = Object.assign({}, this.initialInput)
          this.close()

          return
        }
      }

      this.actionConfirmation = true
      // CAMERA DECOMMISSIONING HANDLE *END*

      this.hasChanged = true
      this.$emit("input", this.input)
      if (this.actionConfirmation) {
        this.save()
      }
    },
    getClickOutsideTarget() {
      return [document.querySelector(".summary-element__input-wrapper")]
    },
  },
}
</script>

<style lang="scss">
.summary-element {
  min-height: 29px;
  box-sizing: border-box;
  transition: min-height 0.1s ease, background 0.2s ease-out;
  &__label {
    color: rgba(0, 0, 0, 0.64);
    min-width: 6rem;
    max-width: 6.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__content {
    min-width: 4rem;
    white-space: nowrap;
  }
  &--highlighted {
    background: #e2f2fc;
    cursor: pointer;
  }
  &--dense {
    .summary-element__label {
      min-width: 2.5rem;
      max-width: none;
    }
  }
  &--editing {
    min-height: 29px;
  }
  pre {
    font-family: inherit;
  }
  .v-input {
    &__slot {
      input {
        font-size: 0.75rem !important;
      }
    }
  }
}
</style>

<style scoped>
.caption {
  letter-spacing: 0.0071428571em !important;
}
.max-w-100 {
  max-width: 100% !important;
}
</style>
