var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-simple-table',{staticClass:"map-table"},[_c('thead',[_c('tr',{staticClass:"py-0"},[_c('th',{staticClass:"text-left py-0"},[_c('ERow',[_c('div',{staticClass:"subtitle-2"},[_vm._v("Location")]),_vm._v(" "),_c('div',[(!_vm.isEditing)?_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":() => {
                    _vm.isEditing = true
                    _vm.draggable = true
                  }}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-pencil-alt ")])],1):_vm._e(),_vm._v(" "),(_vm.isEditing)?_c('v-btn',{staticClass:"pl-2",attrs:{"color":"primary","icon":"","x-small":""},on:{"click":() => {
                    _vm.isEditing = false
                    _vm.draggable = false
                    _vm.savePosition()
                  }}},[_c('v-icon',[_vm._v("fas fa-save")])],1):_vm._e(),_vm._v(" "),(_vm.isEditing)?_c('v-btn',{staticClass:"pl-2",attrs:{"color":"primary","icon":"","x-small":""},on:{"click":() => {
                    _vm.isEditing = false
                    _vm.draggable = false
                    _vm.resetMap()
                  }}},[_c('v-icon',[_vm._v("fas fa-times")])],1):_vm._e()],1)])],1),_vm._v(" "),_c('th',{staticClass:"text-right py-0"},[(_vm.selectedMapView)?_c('v-select',{staticClass:"p-0 my-2",attrs:{"items":_vm.mapViews,"label":"Map types","dense":"","outlined":"","item-text":"name","hide-details":"","return-object":""},model:{value:(_vm.selectedMapView),callback:function ($$v) {_vm.selectedMapView=$$v},expression:"selectedMapView"}}):_vm._e()],1)])]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',{staticClass:"px-0",attrs:{"colspan":"2"}},[(_vm.markerReady)?_c('CameraMapFov',{ref:"map",attrs:{"draggable":_vm.draggable,"is-editing":_vm.isEditing,"marker":_vm.marker,"height":340,"selectedMapView":_vm.selectedMapView},on:{"marker-change":_vm.updateMarker}}):_vm._e()],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }