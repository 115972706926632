<template>
  <Autocomplete
    ref="input"
    :value="value"
    :list-items="dataset"
    :item-text="getProp('item-text', '')"
    :item-value="getProp('item-value', '')"
    :error-messages="errorMessages"
    :hide-details="true"
    :outlined="false"
    append-icon="mdi-menu-down"
    classes="append-outer-icon"
    :return-object="false"
    :menu-props="{
      contentClass: 'summary-element__menu',
      maxHeight: dataset.length > 10 ? 200 : 'none',
      nudgeTop: -1,
    }"
    @input="(v) => $emit('input', v)"
    v-on="$listeners"
  >
    <template #item="{ on, attrs, item }">
      <div v-bind="attrs" :class="item.class" v-on="on">
        {{ item.name }}
      </div>
    </template>
  </Autocomplete>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
export default {
  components: {
    Autocomplete,
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    invalid: { type: Boolean, default: true },
    dataset: { type: Array, default: () => [] },
    errorMessages: { type: Array, default: () => [] },
  },
  methods: {
    getProp(propName, fallback) {
      if (Object.prototype.hasOwnProperty.call(this.$attrs, propName)) {
        return this.$attrs[propName]
      } else {
        return fallback
      }
    },
  },
}
</script>

<style scoped lang="scss">
.append-outer-icon {
  margin-top: 0 !important;
  max-height: 25px;
  .v-input__append-outer .v-icon {
    font-size: 16px !important;
  }
}
</style>
