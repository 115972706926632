<template>
  <v-text-field
    ref="input"
    :value="value"
    class="append-outer-icon"
    :error-messages="errorMessages"
    dense
    hide-details
    @input="(v) => $emit('input', v)"
    v-on="$listeners"
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    invalid: { type: Boolean, default: true },
    errorMessages: { type: Array, default: () => [] },
  },
}
</script>

<style scoped lang="scss">
.append-outer-icon {
  margin-top: 0 !important;
  max-height: 25px;
  .v-input__append-outer .v-icon {
    font-size: 16px !important;
  }
}
</style>
